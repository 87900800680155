import React, { useContext, useEffect } from 'react'

import { Modal as AlliumBaseModal, Box } from '@telus-uds/components-web'
import { SiteBuilderContext } from '../../../renderer/context/SiteBuilderContext'
import alloyEvents from '../../../utils/alloyAnalytics/modal'

export type BaseModalProps = {
  id: string
  entryTitle: string
  hash?: string
  modalMaxWidth?: boolean
  heading?: React.ReactNode
  subHeading?: React.ReactNode
  components: React.ReactNode
  isOpen: boolean
  disableAnalytics?: boolean
}

const BaseModal = ({
  id,
  entryTitle,
  hash,
  modalMaxWidth = false,
  heading,
  subHeading,
  components,
  isOpen,
  disableAnalytics = false,
}: BaseModalProps) => {
  const { setActiveModal, activeModal, registerModal } = useContext(SiteBuilderContext)

  useEffect(() => {
    hash && registerModal(id, hash)
  }, [])

  useEffect(() => {
    if (!disableAnalytics && (isOpen || activeModal === id)) {
      alloyEvents.modalOpen({ id, entryTitle })
    }
  }, [isOpen, activeModal])

  const handleClose = () => {
    setActiveModal(null)

    if (!disableAnalytics) {
      alloyEvents.modalClose({ id, entryTitle })
    }
  }

  const renderTopSpacing = heading ? 3 : 6
  return (
    <AlliumBaseModal
      testID="uds-base-modal"
      isOpen={isOpen || activeModal === id}
      onClose={handleClose}
      maxWidth={modalMaxWidth}
    >
      {heading}
      {heading && subHeading && <Box top={3}>{subHeading}</Box>}
      <div style={{ overflow: 'auto' }}>
        <Box top={renderTopSpacing}>{components}</Box>
      </div>
    </AlliumBaseModal>
  )
}

export default BaseModal
